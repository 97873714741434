.coupon-card
{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 6px #1a181e0a;
    background-color: #fff;
    padding: 15px;
    z-index: 1;
    width: 100%;
}

.coupon-item-container
{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 16px;
    height: 100%;
    width: 100%;
}

.coupon-heading
{
    display: flex;
    width: 100%;
}

.coupon-name
{
    width: 100%;
    float: left;
    text-align: left;
}

.switch-button
{
    width: 100%;
    float: right;
    text-align: right;
}

.name
{
    padding-left: 10px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #1a181e;
}

.coupon-description
{
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4d4d4d;
    padding-bottom: 16px!important;
}

.coupon-divider
{
    border: 1px dashed gray;
    height: 0;
    width: 100%;
}

.status
{
    padding-top: 16px;
    display: flex;
    width: 100%;
}

.times-used-div
{
    display: flex;
    width: 50%;
    float: left;
    text-align: left;
    flex-direction: column;
}

.times-used-div-heading
{
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: gray;
}

.times-used-div-desc
{
    padding-top: 6px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #1e2640;
}

.circle
{
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    background-color: #E3F2FD;
}

.circle1
{
    left: -12px;
    top: 80px;
}

.circle2
{
    right: -12px;
    top: 80px;
}