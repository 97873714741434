.day{
  width:200px;
	border:none;
	background-color:#bdbdbd;
  text-align:center;
}
.slot{
  width:200px;
	border:none;
	text-align:center;
	background-color:#bdbdbd;
}
.days{
  width: 200px;
	height: 30px; 
  border: none; 
  text-align: left; 
  padding: 5px; 
  color: black;
}
.list.active {
    background-color: #ffffff;
    width: 180;
    text-align: center;
    margin: 0 15px;
    border: 1px solid #75c224;
    color:#75c224;
    cursor: pointer;
  }
  .list{
    color:#9b9b9b;
    cursor: pointer;
  }
  .nextbutton{
    color: rgb(255, 255, 255);
        background-image:-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
        box-shadow:0px 14px 24px 0px rgba(93, 183, 96, 0.38);
        height: 50px;
        font-size:18px;
        font-weight: 600;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        overflow: hidden;
        outline: none;
        font-family: Varela Round, sans-serif;
        text-transform: none;
        text-align: center;
      width: 20%;
      margin-left:10px;
  }