.add-container{
	@media only screen and (min-width : 768px) {
		width: 20%;
		background-color: green;
	  }
	
	  /* Medium Devices, Desktops */
	  @media only screen and (min-width : 992px) {
		width: 50%;
		background-color: pink;
	  }
	/* font-family: 'Oxygen',sans-serif; */

}
@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.add-container{
		width:  100%;
		/* margin: 0 -75px; */
		margin-top:-65px;
		background: blue;
		box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
			0 6px 20px 0 rgba(165, 200, 213, 0.41);
		padding-left:70px;
		padding-right:70px;
		padding-bottom: 30px;
		padding-top: 30px;
		border-radius:10px;
		/* font-family: 'Oxygen',sans-serif; */
	
	}
  }
.form-control{
	width:100%;
	margin-bottom:15px;
	margin-left:10px;
}
