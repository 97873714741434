.text-row{
    padding-bottom: px;
}

h5{
    font-weight:600;
}
 .editform_container {
	display: block;
	width: 1000px;	
	/* margin: 0 px; */
	margin-top: 65px;
} 
.add_container{
	width:  100%;
	/* margin: 0 0px;
	margin-top:0px; */
	 background: #fff;
	/* box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
		0 6px 20px 0 rgba(165, 200, 213, 0.41); */
	 padding-left:70px; 
	 padding-right:70px;
	padding-bottom: 30px;
	padding-top: 30px; 
	padding:60px;
	/* margin-left: 30px; */
}

.editform_div {
	padding: 50px;
}
#editable-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
	/* margin: 0 -75px;
	margin-top:-65px; */
}
#editable-form .form-row {
	
padding: 100px;
	font-size: 15px;
	width: 130px;
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: auto;
	line-height: 20px;
	padding-top: 0;
	margin-bottom: 4px;
	margin: 0 -75px;
	margin-top:-65px;
}
#editable-form .form-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	padding: 0 50px;
	margin-left: 50px;
}

#cancel-button,
#submit-button {
	float: right;
	margin-left: px;
}
.nextbutton{
	color: rgb(255, 255, 255);
    	background-image:-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
    	box-shadow:0px 14px 24px 0px rgba(93, 183, 96, 0.38);
    	height: 54px;
    	font-size:18px;
    	font-weight: 600;
    	border-radius: 5px;
    	border-width: 1px;
    	border-style: solid;
    	border-color: transparent;
    	overflow: hidden;
    	outline: none;
    	font-family: Varela Round, sans-serif;
    	text-transform: none;
    	text-align: center;
		width: 34%;
		margin-left:10px;
}
@media screen and (max-width: 768px){
	#nex {
		width:39%;
		position: relative;
		right: 43px;
		height:10%;
	}
}