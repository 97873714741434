*{
    margin: 0;
    padding:50;
}
.type2 input{
    margin-right: "50px";
    border-radius: "5px";
}
label{
padding-right:"50px" ;
} 

.list.active {
    background-color: #ffffff;
    width: 100;
    border: 1px  #75c224;
    color:#75c224;
    cursor: pointer;
    
  }
  .list{

    cursor: pointer;
    scrollbar-width: none;
    -ms-overflow-style: none;
  
  }
  .nextbutton{
    color: rgb(255, 255, 255);
        background-image:-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
        box-shadow:0px 14px 24px 0px rgba(93, 183, 96, 0.38);
        height: 50px;
        font-size:18px;
        font-weight: 600;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        overflow: hidden;
        outline: none;
        font-family: Varela Round, sans-serif;
        text-transform: none;
        text-align: center;
      width: 20%;
      margin-left:10px;
  }