.right {
	float: right;
}

.features {
	box-sizing: border-box;
	padding-top: 72px;
	padding-bottom: 72px;
	height: 100px;
	color: #171e30;
	margin-top: 16px;
	font-size: 28px;
	font-weight: 600;
}
.features-img {
	widows: 215px;
	height: 212px;
}
