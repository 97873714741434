.logo {
	float: left;
}
.logo img {
	height: 50px;
	width: 200px;
	transition: 0.5s;
}
.header {
	height: 70px;
	position: fixed;
	width: 100%;
	top: 0em;
	z-index: 1000;
	background-color: white;
	box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
/* .logout-section {
	float: right;
	margin: 19px 27px 0px;
	height: 39px;
	width: 126px;
}
.logout-section:hover {
	background-color: #65a300;
}
.logout-section h6 {
	padding: 10px 30px 0;
}
.notification-bar {
	height: 70px;
	width: 74%;
	background: white;
	float: right;
	color: black;
} */
