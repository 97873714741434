.heading{
    position: relative;
    
    bottom: 63px;
    left: 31px; color: black; font-size: 24px; font-family: Nunito; font-weight: 700; text-transform: capitalize; line-height: 22px; word-wrap: break-word
}
.refresh-btn{
    width: 34%; height: 50%; background: #6AA92A; border-radius: 39px; color:#FFFFFF;position: relative;
    left: 30%;}
.icon{
    width: 100%; height: 100%; 
}
.notfound {
    display:grid;
    justify-content: center;
    align-items: center;
  }