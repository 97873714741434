.check-box-header {
	color: #3f51b5;
	font-weight: 800;
	font-size: medium;
}
.form-label {
	font-size: 13px;
	color: #33475b;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 4px;
}

#signup-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
	
}

.buttons{
	display: flex;
	justify-content: flex-end;
}