.banner {
	width: 100%;
	height: auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	justify-content: center;
	font-family: 'Oxygen', sans-serif;
	background-image:-webkit-linear-gradient(45deg, rgb(255,255,255) 0%, rgb(239,255,251) 100%);
	display: flex;
	text-align: justify;
	align-items: center;
	padding-top:50px;
	padding-bottom: 50px;
}
.heading {
	color:  rgb(1, 41, 13);
	font-family: 'Oxygen', sans-serif;
	font-weight: 900;
	font-size: 48px;
	line-height: 50px;
	padding-left: 50px;
	padding-top: 130px;
}

.banner-content {
	font-family: 'Oxygen', sans-serif;
	display: flex;
	text-align: justify;
	justify-content: center;
	align-items: center;
}

.description {
	font-family: 'Oxygen', sans-serif;
	box-sizing: border-box;
	font-weight: 80;
	padding-top:20px;
	color: #022404;
    font-weight: 90;
	font-size: 21px;
	line-height: 35px;
}
.buttondiv {
	box-sizing: border-box;
	display: flex;
	padding-bottom: 40px;
	padding-top: 32px;
	font-family: 'Oxygen', sans-serif;
}

