/* .NewOrderPage{
    
    width: "80%";
} */
.btnStyles{
    display: flex;
    gap:30px;
}
.neworderBox{
    width: 100%;
    height: 60px;
    background-color: #85BE4940;
    border-radius: 15px;
    margin-top: 20px;
    display: flex; /* Add flex display */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-family: "Roboto";
}
.textStyleNew{
    font-weight: 550;
    font-family: "Roboto";
    margin-bottom: 0px;
    
}
  
.gridStyle{
    
        display: grid;
        column-gap: 200px;
        margin-top: 30px;
        grid-template-columns: 100px 300px 700px;
        width: 90%;
        margin-left: 80px;
    
}
.image_container{
    width: 100%;
    /* margin-left: 80px; */
    margin-top: 30px;
}
.gridStyle2{
    
    display: grid;
    column-gap: 520px;
    margin-top: 30px;
    grid-template-columns: auto auto;
    width: 90%;
    margin-left: 80px;

}
.image_container2{
    width: 100%;
    /* margin-left: 180px; */
    /* margin-top: 10px; */
}
.gridStyle3{
    
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    gap: 35px;
    width: 90%;
    margin-left: 0px;
    text-transform: lowercase;
}
.helpTag{
    color: #85BE49;
    margin: auto;
    text-decoration: none;
}
#buttonContainer {
  display: flex;
 justify-content: center;
 gap: 30px; /* Adjust the distance between buttons as needed */
}

.MainActiveOrder{
    display: flex;
    
    font-family: "Roboto";
     font-weight: 400;
     color: #646464;
}
.MainActive{
    font-family: "Roboto";
    font-weight: 400;
    color: #646464;
}
.nameStyle1{
    /* font-family: "Nunito"; */
    font-weight: 700;
    padding-left: 0px;
    margin-bottom: 0px;
    font-family: "Roboto";
}
.address{
    margin-left: -58px;
     font-weight: 700;
     font-family: "Roboto";
     padding-left: 0px;
}
.address2{ 
    margin-left: -58px;
     font-weight: 700;
     font-family: "Roboto";
     padding-left: 0px;
}
.numberStyle1{
    margin-right: 0px;
    font-weight: 700;
    font-family: "Roboto";
    /* margin-left: 10px; */
}
    
.MainActives{
  
    display: flex;
    /* gap: 3px; */
    font-family: "Roboto";
    font-weight: 400;
    
    color: #85BE49;
  
  
}
.productDetailsNew{
    font-weight: 500;
    font-family: "Roboto";
    margin-top: 10px;
    
}
.connecting-lineNew {
    border-left: 3px solid #85BE49;
    height: 94%;
    position: absolute;
    left: 4px;
    /* margin-left: -3px; */ 
    top: 5px;
}
.pButton{
    font-weight: 600;
    
   font-family: "Roboto";
 text-transform: capitalize;
margin-bottom:0px;
}