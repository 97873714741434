/* .pageStyle {
  background-color: #E0E0E0;
  height: 100vh;
} */

.firstBox {
    width: 90%;
    height: 55%;
    border-radius: 25px;
    background-color: white;
    margin-top: 50px;
    /* margin-left: 50px; */
    /* display: flex; */
    /* align-items: center; */
  }
  
  /* .gridFirstBox {
    display: grid;
    grid-template-columns: auto auto;
    gap: 450px;
  } */
  .nameContent{
    font-family: "Roboto";
    font-weight: 700;

  }
  .MainReadyOrder{
    /* display: flex;
    font-family: "Roboto";
    font-weight: 400;
    color: #646464; */
    display: flex;
    gap: 0px;
    font-family: "Roboto";
    font-weight: 400;
}
.MainReady{
  font-family: "Roboto";
  font-weight: 400;
  color: #646464;
}
.MainReadyForDelivery{
  display: flex;
  font-family: "Roboto";
  font-weight: 400;
  color: #85BE49;
}  
  /* .firstBox span {
    white-space: nowrap;
  }
   */
.address{
    /* margin-left: 2px; */
     font-weight: 700;
     font-family: "Roboto";
     padding-left: 0px;
}  
  
.gridFirstBox > div:first-child {
    justify-self: start;
}
  
.gridFirstBox > div:last-child {
    justify-self: end;
}
.firstBox > div:last-child {
    margin-top: 10px; 
}
.readyfordeliveryBox{
  width: 100%;
  height: 60px;
  background-color:rgba(133, 190, 73, 0.25);
  border-radius: 15px;
  margin-top: 20px;
  display: flex; /* Add flex display */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  font-family: "Roboto";
}
.nameStyleReady{
  font-family: "Roboto";
  font-weight: 700;
}  
.addressReady{
    /* margin-left: 8px; */
     font-weight: 400;
     font-family: "Roboto";
     text-align: left;
     margin-bottom: 0px;
}
.numberStyle{
    margin-right: 20px;
    font-weight: 700;
    font-family: "Roboto";
}
.itemDetails{
    font-weight: 400;
    font-family: "Roboto";
}
.productDetails{
    font-weight: 500;
    font-family: "Roboto";
    /* margin-top: 20px; */
    
}
.textStyleReady{
    font-weight: 600;
    font-family: "Roboto";
    text-align: center;
    
}
  
.deliveryButton{
    font-family: "Roboto";
    font-weight: 700;
    
}
  .ReadyForDeliveryGrid{
    display: grid;
    column-gap: 78px;
    margin-top: 30px;
    grid-template-columns: 98px 490px 900px;
    width: 90%;
    margin-left: 60px;
  }
.vertical-timeline::before{
  content: '';
  position: absolute;
  top: 36px;
  left: 18px;
  height: 150px;
  width: 4px;
}
.vertical-timeline{
  
    width: 50%;
    position: relative;
    padding-left: 40px;

}
.timeline-container{
  width:40%;
 
} 
.customTimelineItem:before {
  content: none; 
}
.connecting-line {
  border-left: 3px solid #85BE49;
  height: 100%;
  
  position: absolute;
  left: 4px;
  /*margin-left: -3px;*/
  top: 5px;
 
}
.imageReady{
  width: "100%";
}
.customReady{
  padding-top: 0px !important;
}
.timeline-containerReady {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  overflow: hidden;
}

.timeline-entryReady {
  display: flex;
  gap: 10px;
}

.timeline-markerReady {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 80px;
}

.dotReady {
  background: #85BE49;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.connecting-lineReady {
  border-left: 3px solid #85BE49;
  height: calc(100% - -19px);
  position: absolute;
  top: 10px;
}

.timeline-contentReady {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  line-height: 1;
  /* gap: 10px; */
  text-align: left;
  width: 100%;
}