.text-row{
    padding-bottom:50px;
}

h5{
    font-weight:600;
    padding-bottom: 20px;
    
}
.list{

    cursor: pointer;
    scroll-margin-block: "nooverlap";
    scroll-margin-block-end: "nooverlap";
  }
  .nextbutton{
	color: rgb(255, 255, 255);
    	background-image:-webkit-linear-gradient( 59deg, rgb(83,170,73) 0%, rgb(117,194,36) 100%);
    	box-shadow:0px 14px 24px 0px rgba(93, 183, 96, 0.38);
    	height: 60px;
    	font-size:18px;
    	font-weight: 600;
    	border-radius: 5px;
    	border-width: 1px;
    	border-style: solid;
    	border-color: transparent;
    	overflow: hidden;
    	outline: none;
    	font-family: Varela Round, sans-serif;
    	text-transform: none;
    	text-align: center;
		width: 34%;
		margin-left:10px;
}
@media screen and (max-width: 768px){
	#nex {
		width:39%;
		height:10%;
		position: relative;
        left: 16px
	}
}
