.login_div {
	padding: 40px;
}
div {
	display: block;
}
form {
	display: block;
	margin-top: 0em;
	margin-block-end: 1em;
	font-family: 'Oxygen', sans-serif;
}

.login-heading {
box-sizing: border-box;
color: rgb(20, 59, 37);
font-weight: 600;
padding-top: 20px;
margin-bottom: 6px;
font-size: 16px;
line-height: 44px;
justify-content: center;
align-items: center;
text-align: center;
font-family: 'Oxygen', sans-serif;
}

h1 {
	display: block;
	font-size: 2em;
	margin-block-start: 0.67em;
	margin-block-end: 0.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: 300;
	font-family: 'Oxygen', sans-serif;

}
h2 {
	display: block;
	font-size: 1.5em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
	font-family: 'Oxygen', sans-serif;

}
#login-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
}
.login-row {
	font-size: 14px;
	width: 130px;
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: left;
	line-height: 20px;
	padding: 2px 40px 4px 40px;
	margin-bottom: 4px;
}
.login-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	padding-top: 10px;
}
.login_field_input {
	border: 0;
	border-radius: 0;
	background: none;
	border-bottom: 2px solid #cad5e2;
	width: 100%;
	outline: none;
	font-family: 'Oxygen', sans-serif;

}
.login-label {
	font-size: 13px;
	color: #33475b;
	font-weight: 500;
	line-height: 20px;
	margin-bottom: 4px;
	font-family: 'Oxygen', sans-serif;

}

.password_btn {
	cursor: pointer;
	font-size: 12px;
	/* line-height: 16px; */
	font-weight: 600;
	color: rgb(23, 177, 9);
	padding: 10px;
	background: none;
	border-width: initial;
	border-style: none;
	border-color: initial;
	border-image: initial;
	font-family: 'Oxygen', sans-serif;

}
#login-btn {
	justify-content: center;
	color: rgb(255, 255, 255);
	background-color: rgb(23, 177, 9);
	min-width: auto;
	width: 100%;
	font-size: 16px;
	font-weight: 600;
	border-radius: 25px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	border-image: initial;
	overflow: hidden;
	outline: none;
	height: 45px;
	position: relative;
	left: 5px;
	text-align: center;
	align-items: center;
	display: flex;
	cursor: pointer;
}

.login_container {
	box-sizing: border-box;
	/* margin-left:15%;
	margin-right: -100px; */
	color: rgb(255, 255, 255);
	font-size: 14px;
	background-color: white;
	min-width: fit-content;
	float: right;
	/* width: 350px; */
	box-shadow: 5px 10px 18px #888888;
	height: 330px;
	border-radius: 25px;
	margin-top:3px;
	font-family: 'Oxygen', sans-serif;
	padding: 10px;
}
