.add-container {
  width: 1000px;
  margin: 0 -75px;
  margin-top: -65px;
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
    0 6px 20px 0 rgba(165, 200, 213, 0.41);
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 30px;
  padding-top: 30px;

}

.form-control {
  width: 100%;
  margin-bottom: 15px;
  /* margin-left:10px; */
}


.Card {
  display: block;
  width: 250px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  padding: 25px;
  transition: all 0.2s;
  align-items: center;
  background-color: white;
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 18px 0px;
}

.Card:hover {
  transform: scale(1);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.Card .icon {
  width: 100px;
  position: absolute;
  margin-top: -50px;
  top: 50%;
  left: -10px;
  transition: all 0.2s;
}

.Card:hover .icon {
  filter: grayscale(90%);
}

.Card h1 {
  text-transform: uppercase;
  color: rgb(67, 176, 42);
  font-size: 20px;
  letter-spacing: 0.05em;
  font-weight: 600;
  transition: all 0.2s;
}

.Card:hover h1 {
  color: #655;
}

.Card p {
  font-size: 14px;
  color: #655;
  letter-spacing: 0.03em;
  line-height: 1.75em;
}

.product-image {
  width: 150px;
  height: 150px;
}

.product-padding {
  padding-bottom: 10px;
}

.Promoproduct-padding {
  padding-bottom: 10px;
  text-decoration: line-through;
}

.product-name {
  height: 50px;
  text-overflow: ellipsis;
}

.addbutton {
  width: 50%;
}

.float-right {
  float: right;
}

.edit_container {
  height: 100px;
  width: 95%;
}

.form-container {
  height: 250px;
  width: 750px;
  background-color: white;
  padding-bottom: 20px;
}

.form_div {
  height: auto;
}

.center-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DotButton {
  border: none;
  background: none;
}

.EditDiv {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #061303;
}

.divider {
  width: 250px;
  height: 0px;
  opacity: 0.3;
  border: 0.5px solid #59626A;
}
.PopupPaper{
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 18px 0px;
  cursor:pointer;
}
.EditDivButton{
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #061303;
  justify-content: center;
  align-items: center;
  display: flex;
  width:50px;
  box-shadow: rgb(0 0 0 / 4%) 0px 3px 5px -1px, rgb(0 0 0 / 4%) 0px 6px 10px 0px, rgb(0 0 0 / 4%) 0px 1px 18px 0px;
}
.noresult{
  max-width:810px;
}
@media screen and (max-width: 768px){
  .noresult{
  width:286px;
  position: relative;
  left: 31px;
  }
  
} 