.SHOP-image {
	height: 38px;
}
input:focus { 
	outline: none !important;
	border-radius: 13px;
	border:1px solid rgba(196, 196, 196, 0.2);
	background: rgba(196, 196, 196, 0.2);
}
textarea:focus { 
	outline: none !important;
	border:1px solid rgba(196, 196, 196, 0.2);
	background: rgba(196, 196, 196, 0.2);

}