.rst__lineHalfHorizontalRight::before, 
.rst__lineFullVertical::after,
 .rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background:  rgb(137, 199, 74, 1);
        border:1.2px solid  rgb(137, 199, 74, 1); 
        /* background-image: -webkit-linear-gradient( 59deg, rgb(137, 199, 74, 1) 0%, rgb(117,194,36) 100%);
		border-image: initial; */
}

.rst__rowContents 
{     @extend %rowItem; position: relative;
      cursor: pointer;
      height: 100%;
      border: solid #BBB 1px;
      border-top-left-radius:15px;
      box-shadow: 0 2px 2px -2px; 
      padding: 0 5px 0 10px;
      border-radius: 2px;
      min-width: 230px; 
      flex: 1 0 auto; display: flex; 
      align-items: center;
      justify-content: space-between;
      background-color: white; 
      border-bottom-right-radius:15px;
      border-top-right-radius:15px;
      font-family: 'Oxygen',sans-serif;
}
.node-inactive{
    @extend %rowItem;
    position: relative;
    cursor: pointer;
    height: 100%;
    border: solid #BBB 1px;
    border-top-left-radius:15px;
    box-shadow: 0 2px 2px -2px; 
    padding: 0 5px 0 10px;
    border-radius: 2px;
    min-width: 230px; 
    flex: 1 0 auto; display: flex; 
    align-items: center;
    justify-content: space-between;
    background-color: rgb(180, 174, 174); 
    border-bottom-right-radius:15px;
    border-top-right-radius:15px;
    font-family: 'Oxygen',sans-serif;
}
.rst__rowWrapper:hover .rst__rowContents
 { 
    background-image: -webkit-linear-gradient( 59deg, rgb(137, 199, 74, 1) 0%, rgb(117,194,36) 100%);
    border-image: initial;
     color: white;
     font-family: 'Oxygen',sans-serif;
 }
.add-container{
	width:  1000px;
	margin: 0 -75px;
	margin-top:-65px;
	background: #fff;
	box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
		0 6px 20px 0 rgba(165, 200, 213, 0.41);
	padding-left:70px;
	padding-right:70px;
	padding-bottom: 30px;
    padding-top: 30px;
    font-family: 'Oxygen',sans-serif;
}
.form-control{
	width:100%;
	margin-bottom:15px;
    margin-left:10px;
    font-family: 'Oxygen',sans-serif;
}
.col-Row{
    width: 100%;
    padding-left: 25px;
    padding-top: 30px;
    font-family: 'Oxygen',sans-serif;
}
.update_btn{
    height: 30px;
    width: 50px;
    background-color: rgb(13, 172, 13) 1px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border: none;
    font-family: 'Oxygen',sans-serif;
}