/**
 * Extra class applied to VirtualScroll through className prop
  */
 .rst__moveHandle
 {
	background-image : none;
	padding-top : 6px;
	text-align: center;
}
.icon-a> .rst__moveHandle::before {
	content : "\f07c";
	color :green;
	font : normal normal normal 24px FontAwesome;
}
.icon-b> .rst__moveHandle::before {
	content : "\f07b";
	color : green;
	font : normal normal normal 24px FontAwesome;
}

.rst__virtualScrollOverride {
  overflow: auto !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}
