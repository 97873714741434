.editform_container {
	display: block;
	width: 100%;
	padding-left: 50px;
	padding-right: 50px;
	background: #fff;
	/* margin: 0 -85px; */
	margin-top: -85px;
	margin: 0px;
	/* margin-bottom:-80px; */
	/* box-shadow: 0 4px 8px 0 rgba(53, 105, 128, 0.3),
		0 6px 20px 0 rgba(165, 200, 213, 0.41); */
}

.editform_div {
	padding: 100px;
	width: 100%;
}

#editable-form {
	width: 100%;
	font-family: arial, helvetica, sans-serif;
	box-sizing: border-box;
}

#editable-form .form-row {
	padding: 40px;
	font-size: 14px;
	width: 150px;
	text-align: right;
	color: #33475b;
	display: block;
	float: none;
	width: auto;
	font-weight: 500;
	text-align: left;
	/* line-height: 20px; */
	padding-top: 10;
	margin-bottom: 4px;
}

#editable-form .form-Col {
	align-self: flex-start;
	flex: 1 1 100%;
	padding: 0 10px;
}

#cancel-button,
#submit-button {
	width: 103px;
	float: right;
	/* margin-left: 15px; */
	margin-right: 30px;
	margin-top: 8px;
	background-color: darkgreen;
	border-radius: 4px;
	padding: 10px 0 10px 0;
	color: floralwhite;
	/* margin-bottom: 30px; */

}

.searchBtn {
	color: rgb(255, 255, 255);
	background-image: -webkit-linear-gradient(59deg, rgb(83, 170, 73) 0%, rgb(117, 194, 36) 100%);
	height: 55px;
	font-size: 18px;
	font-weight: 600;
	border-radius: 5px;
	border-width: 1px;
	border-style: solid;
	border-color: transparent;
	overflow: hidden;
	outline: none;
	/* min-height: 14px; */
	font-family: Varela Round, sans-serif;
	text-transform: none;
	text-align: center;
	/* margin-left: 20px; */
	width: 100%;
}
.searchBtn:focus { outline: none !important;;}

.clearBtn {
	color: rgb(83,170,73);
	height: 55px;
	font-size: 18px;
	font-weight: 600;
	border-radius: 5px;
	border-color: #33475b;
	border-width: 1px;
	border-style: solid;
	overflow: hidden;
	outline: none;
	/* min-height: 14px; */
	font-family: Varela Round, sans-serif;
	text-transform: none;
	text-align: center;
	/* margin-left: 20px; */
	width: 100%;
	border: none;
}
.clearBtn:focus { outline: none !important;;}
.formgrid{
	padding:'20px';
}
@media (max-width: 768px){
	.formgrid{
		padding:'14px';
		position:relative;
		right:'19px';
		

	}
}