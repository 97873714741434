body {
    padding: 24px;
  }
  @media (max-width: 768px) {
  body{
    padding:14px;
  }
  }
  .reactEasyCrop_Container{
    position: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    user-select: none;
    touch-action: none;
    cursor: move;
    display: 0;
    justify-content: 0;
    align-items: 0;
  } 
  .ant-modal .ant-modal-content {
    position: relative;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
}
.ant-modal .ant-modal-body {
  font-size: 14px;
  line-height: 1.5714285714285714;
  word-wrap: break-word;
  padding: 0;
}
.ant-modal-root .ant-modal-wrap {
  position: fixed;
  inset: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  margin-left: 300px;
}
.ant-btn-default {
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}
.ant-btn-primary {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.ant-modal .ant-modal-footer {
  text-align: end;
  background: transparent;
  margin-top: 12px;
  padding: 0;
  border-top: none;
  border-radius: 0;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select >.ant-upload, :where(.css-dev-only-do-not-override-1ae8k9u).ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select >.ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-btn {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
}

.ant-btn {
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  color: rgba(0, 0, 0, 0.88);
}